export const orderAgeMarks = [
    {
        value: 1,
        label: '1 day',
    },
    {
        value: 2,
        label: '2 days',
    },
    {
        value: 3,
        label: '3 days',
    },
    {
        value: 4,
        label: '7 days',
    },
    {
        value: 5,
        label: '14 days',
    },
    {
        value: 6,
        label: '21 days',
    },
    {
        value: 7,
        label: '30 days',
    },
    {
        value: 8,
        label: '60 days',
    },
    {
        value: 9,
        label: 'All',
    },
]

export const driverLastSeenMarks = [
    {
        value: 1,
        label: '1day',
    },
    {
        value: 2,
        label: '2 days',
    },
    {
        value: 3,
        label: '3 days',
    },
    {
        value: 4,
        label: '7 days',
    },
    {
        value: 5,
        label: '14 days',
    },
    {
        value: 6,
        label: '21 days',
    },
    {
        value: 7,
        label: '30 days',
    },
]

export const driverVehicleTypes = [
    { value: "all", label: "All" },
    { value: "0", label: "car" },
    { value: "1", label: "motorbike" },
    { value: "2", label: "van" },
    { value: "3", label: "minivan" },
    { value: "4", label: "pickup" },
    { value: "5", label: "small_truck" },
    { value: "6", label: "large_truck" },
]

export const driverDeviceTypes = [
    { value: "all", label: "All" },
    { value: "android", label: "android" },
    { value: "ios", label: "ios" },
]

export const driverUniformTypes = [
    { value: "all", label: "All" },
    { value: "0", label: "branded" },
    { value: "1", label: "unbranded" },
]

export const driverStatusTypes = [
    { value: "all", label: "All" },
    { value: "free", label: "Online available" },
    { value: "busy", label: "Online busy" },
    { value: "offline", label: "Offline" },
]

export const shipmentHubStatuses = [
    { value: "all", label: "All" },
    { value: "true", label: "Open hubs" },
    { value: "false", label: "Closed hubs" },
]

export const DRIVER_LAST_SEEN_MIN_MARK = 1
export const DRIVER_LAST_SEEN_MAX_MARK = 7